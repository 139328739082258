<template>
  <div class="container">            
    <h3 class="pagetitle">Login Log</h3>        
    <form @submit.prevent="sendDate()" class="searchBar">
      <div class="horizontal-form"> 
        <div>
          <input type="text" id="startYear" v-model="startYear" placeholder="Year" />
          <input type="text" id="startMonth" v-model="startMonth" placeholder="Month" />
          <input type="text" id="startDay" v-model="startDay" placeholder="Day" />
        </div>   
        <div>~</div> 
        <div>
          <input type="text" id="endYear" v-model="endYear" placeholder="Year" />
          <input type="text" id="endMonth" v-model="endMonth" placeholder="Month" />     
          <input type="text" id="endDay" v-model="endDay" placeholder="Day" />
        </div>
        <div>
          <button type="submit" class="searchButton" @click="callSearchData">Search</button>                        
        </div>        
        <div>
          <button class="excelButton" @click="downExcel">Excel</button>                                              
        </div>                    
      </div>      
    </form>            
    <div class="tables">
        <table id="productionResult" class="custom-table">
        <tr>          
          <th>ID</th><th>Time</th><th>IP Address</th>          
        </tr>        
        <tr v-for="row in item" :key="row.id">            
          <td>{{ row.loginid}}</td>
          <td>{{ row.lastlogindate }}</td>
          <td>{{ row.accessip }}</td>          
        </tr>
  </table>    
  </div>
</div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import axios from 'axios';
import { downloadExcel, } from "../../utils.js"; 

export default {
methods: {   
  downExcel() { downloadExcel(this.item, "Login Log.xlsx"); },
},

setup() {
  const startYear = ref(); const startMonth = ref(); const startDay = ref(); const endYear = ref(); const endMonth = ref(); const endDay = ref(); 

  const dbTableName = 'loginlog';    
  const tableData = reactive({ data: [], });    

  const sendDate = async () => {const sendDateData = [startYear.value, startMonth.value, startDay.value, endYear.value, endMonth.value, endDay.value, ]; return {sendDateData};};     
  const searchData = async () => {
    const sendDateData = await sendDate();
    const formData = {        
        tableName: dbTableName,
        startYear: sendDateData.sendDateData[0],
        startMonth: sendDateData.sendDateData[1],
        startDay: sendDateData.sendDateData[2],
        endYear: sendDateData.sendDateData[3],
        endMonth: sendDateData.sendDateData[4],
        endDay: sendDateData.sendDateData[5],  
    };    
    axios.get('/api/loginLogSearch', { params: formData })
      .then((res) => { 
        tableData.data = res.data
      });
  };  

  const callSearchData = () => { searchData(); };  

  const item = computed(() => { return tableData.data.map(d => { return { ...d, }; }); });

  return {
    startYear, startMonth, startDay, endYear, endMonth, endDay, 
    dbTableName, item, 
    searchData, callSearchData, sendDate, 
  };
},
};
</script>

<style scoped>
 * { box-sizing: border-box;}

.container { margin-top: 0px; padding-top: 10px; max-width: 100%; margin-left: auto; margin-right: auto;}
.horizontal-form { margin-bottom: 20px; display: flex; flex-wrap: wrap; gap: 10px; align-items: center; }   
.start,.end { display: flex; flex-direction: row; border: 0px;}
.start input,.end input { margin-right: 5px;}
.separator { font-size: 24px; margin: 0 10px; border: 0px;}
.searchTd { border: 0px;}

input::placeholder { color: gray;}

select,input {
  padding: 8px;  border: 1px solid #ccc;  border-radius: 4px;
  font-size: 14px; width: 100%; max-width: 100px;
}

.searchBar {
  display: flex; flex-wrap: nowrap; justify-content: flex-start; align-items: center;
  margin-left: 10px; margin-top: 10px;
}
.searchBar > *:not(:last-child) {  margin-right: 5px; }

.searchBar select,.searchBar input{ width : 100px; margin-right: 5px; padding : 5px; }


.tables { display: flex; flex-direction: column; align-items: stretch; padding : 0px 10px; width: 100%; }
td { border : 1px solid black; text-align : center; padding: 5px 10px; font-size : 12px; }
th { position: sticky; top: 0; z-index: 10; background-color: rgb(230, 230, 230) ; border : 1px solid black; text-align : center; padding: 5px 10px; font-size : 12px; }
.contents { width: 500px !important; text-align: left;}

.pagetitle { text-align: left; font-size: 1.0rem; font-weight: bold; margin-left : 10px; }
.disabledButton { background-color: gray !important; cursor: not-allowed !important; }
.confirmButton {
    padding: 5px 5px; background-color: #1e00ca; color: #fff; border: none; border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}

.gray-background { background-color: gray;} 

.searchButton, .excelButton, .addButton { width: 100px; height: 30px !important; font-size: 15px}
.searchButton {
    background-color: #929292;  color: rgb(0, 0, 0);
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px;
}
.searchButton:hover { background-color: #a4a4a4; }
.excelButton {
    background-color: #347003;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; 
}
.excelButton:hover { background-color: #009625; }
.addButton {
    background-color: #007396;  color: white;
    border: none; border-radius: 4px; cursor: pointer;  font-weight: bold; margin-right : 10px;
    padding: 0.1rem 0.5rem; transition: background-color 0.3s; margin-left : 10px; 
}
.addButton:hover { background-color:  #3498db; }
.moButton {
    padding: 5px 5px; background-color: #0d4d01; color: #fff; border: none; border-radius: 4px;
    font-size: 14px; font-weight: 600; margin-left: 5px;
    margin-right: 5px; cursor: pointer; transition: background-color 0.3s;        
}
.del {
  padding: 5px 5px;
  background-color: #bfbebe; 
  color: #000000; border: 10px; border-color: #000000; border-radius: 4px;
  font-size: 14px; font-weight: 600; cursor: pointer; 
}

</style>