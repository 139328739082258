<template>
  <div class="app">    
    <div v-if = "state.account.id">      
      <Ground/>    
      <button @click="logout()" class="logout">log out</button>
    </div>    
    <div v-else class="login-form">   
      <h1 class="title">LSEVP Production WEB</h1>   
       <label for="loginId">
        <span>ID</span>
        <input type="text" id="loginId" v-model="state.form.loginId"/>
      </label>
      <label for="loginPw">
        <span>Pass word</span>
        <input type="password" id="loginPw" v-model="state.form.loginPw"/>
      </label>
      <hr />
      <div class="login-button-container">
      <button @click="submit()" class="login">log-in</button>
      </div>
      <h1 class="caution">CAUTION</h1>
      <h1 class="legal-statement">
      Any unauthorized access, use, copying, distribution, or modification of this website without the permission of KWON.YS is strictly prohibited and will result in legal liability. By logging in, you accept full responsibility and acknowledge that this website and its code are the exclusive property of KWON.YS.
    </h1>
    </div>
  </div>  
</template>

<script>
import axios from 'axios';
import { reactive, } from "vue";
import Ground from "./components/GroundOne.vue";
import Cookies from 'js-cookie';
export default {

  components:{Ground},  

  setup(){
    const state = reactive({
      account:{
        id: null,
        name: "",
      },
      form:{
        loginId: "",
        loginPw: "",
      }
  });

  const submit = async () => {
    const args = {
      loginId : state.form.loginId,
      loginPw : state.form.loginPw,
    };    
 
    try {
      const res = await axios.post('/api/accountPost', args, { withCredentials: true });
      alert("login success");
      state.account = res.data;   
      localStorage.setItem("account", JSON.stringify(res.data));
      
      // Update last login time after successful login
      await axios.post("/api/updateLastLogin", {
        loginId: state.form.loginId,
      });
      
      window.location.reload(); // Refresh page
    } catch (error) {
      alert("login failed");
    }
  };   

  axios.get('/api/accountGet')
  .then(res => {
    state.account = res.data;
  })
  .catch(error => {
    if (error.response && error.response.status === 401) {
      // 401 오류를 무시      
    } else {
      console.error('Error:', error);
    }
  });
    
  const logout = () => {   
    axios.delete("/api/accountDelete", { withCredentials: true }).then(() => {
      alert("You have logged out.")
      state.account.id = null;
      state.account.name = "";
      localStorage.removeItem('account');  
      Cookies.remove('token', { path: '/', sameSite: 'None', secure: true });      
      }).catch((error) => { 
        console.error("Logout failed: ", error);
    });
  }; 

  return { state, submit, logout} ;
  },
};

</script>


<style scoped>
.login-form {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
}


label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}

span {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;  
}


input:focus {
  border-color: #5b9dd9;
}


button {
  width: 100%;
  padding: 10px;
  background-color: #5b9dd9;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;    
}

button:hover {
  background-color: #4290c4;
}

button:disabled {
  background-color: #d1d1d1;
  cursor: not-allowed;
}

hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

.logout {
  bottom: 20px;
  left: 20px;
  padding: 6px 12px; /* Adjust padding for smaller size */
  font-size: 14px; /* Reduce font size */
  border-radius: 4px; /* Adjust border-radius for smaller size */
  width: auto; /* Set the width to auto to fit the content */
  white-space: nowrap; /* Prevent the text from wrapping */ 
  background-color: #c2c2c2;
  color: #000000; 
  margin-left: 15px;
  margin-bottom: 10px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #2680f5;
  text-align: left;
  margin-left: 5px;
  margin-top: 20px;    
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.legal-statement {
  font-size: 14px;
  font-weight: bold;
  color: #333;  
  padding: 0 5px;  
}

.caution {
  font-size: 22px;
  font-weight: bold;
  color: #ff0202;
  text-align: left;
  margin-left: 5px;
  margin-top: 20px;    
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}


</style>
