// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import axios from 'axios';

import Tr2smtPr from './components/production/tr2smt/tr2smt_pr.vue';
import Tr2fpcaPr from './components/production/tr2fpca/tr2fpca_pr.vue';
import Tr2crimpingPr from './components/production/tr2crimping/tr2crimping_pr.vue';
import Tr2bfa2pPr from './components/production/tr2bfa2p/tr2bfa2p_pr.vue';
import Tr2bfa3pPr from './components/production/tr2bfa3p/tr2bfa3p_pr.vue';
import Ford1frPr from './components/production/ford1fr/ford1fr_pr.vue';
import Ford1rrPr from './components/production/ford1rr/ford1rr_pr.vue';
import Ford2frPr from './components/production/ford2fr/ford2fr_pr.vue';
import Ford2rrPr from './components/production/ford2rr/ford2rr_pr.vue';
import Ford3frPr from './components/production/ford3fr/ford3fr_pr.vue';
import Ford3rrPr from './components/production/ford3rr/ford3rr_pr.vue';
import Fca1Pr from './components/production/fca1/fca1_pr.vue';
import Fca2Pr from './components/production/fca2/fca2_pr.vue';
import Bt6Pr from './components/production/bt6/bt6_pr.vue';
import Bmw12vPr from './components/production/bmw12v/bmw12v_pr.vue';
import Bmw48vPr from './components/production/bmw48v/bmw48v_pr.vue';

import FcaFlexBcPr from './components/production/fca_flex/busbarCarrier/fca_flex_bc_pr.vue';
import FcaFlexPoPr from './components/production/fca_flex/positive/fca_flex_po_pr.vue';
import FcaFlex67Pr from './components/production/fca_flex/67/fca_flex_67_pr.vue';
import FcaFlexNePr from './components/production/fca_flex/negative/fca_flex_ne_pr.vue';
import FcaFlex45Pr from './components/production/fca_flex/45/fca_flex_45_pr.vue';

import Tr3smtPr from './components/production/tr3smt/tr3smt_pr.vue';
import Tr3crimpingPr from './components/production/tr3crimping/tr3crimping_pr.vue';
import Tr3fpcaPr from './components/production/tr3fpca/tr3fpca_pr.vue';
import Tr3bfa2pPr from './components/production/tr3bfa2p/tr3bfa2p_pr.vue';
import Tr3bfa3pPr from './components/production/tr3bfa3p/tr3bfa3p_pr.vue';
import EvoPr from './components/production/evo/evo_pr.vue';
import thermistorTape from './components/production/thermistorTape/thermistorTape_pr.vue';
import thermistorPad from './components/production/thermistorPad/thermistorPad_pr.vue';
import bendingEvo from './components/production/bendingEvo/bendingEvo_pr.vue';
import Ev2020_60FrPr from './components/production/ev2020_60fr/ev2020_60fr_pr.vue';
import Ev2020_60RrPr from './components/production/ev2020_60rr/ev2020_60rr_pr.vue';
import Ev2020_4060FrPr from './components/production/ev2020_4060fr/ev2020_4060fr_pr.vue';
import Ev2020_4060RrPr from './components/production/ev2020_4060rr/ev2020_4060rr_pr.vue';

import packingPr from './components/production/packing/packing_pr.vue';

import resultVerification from './components/resultVerification/resultVerification.vue';

import searchPeriod from './components/report/searchPeriod/searchPeriod.vue';
import report01 from './components/report/report/reportOne.vue';
import report02 from './components/report/daily/reportTwo.vue';
import monthlyReport from './components/report/monthlyReport/monthlyReport.vue';
import osCost from './components/report/osCost/osCost.vue';
import failureReport from './components/report/failureReport/failureReport.vue';
import correctiveAction from './components/correctiveAction/correctiveAction.vue';
import investList from './components/invest/investList.vue';
import assetList from './components/assetList/assetList.vue';
import documentReport from './components/documentReport/documentReport.vue';

import shippingAllProject from './components/shippingAndInventory/allProjectPlanAndResult.vue';

import targetList from './components/target/targetList.vue';
import ngList from './components/ngList/ngList.vue';
import excelupload from './components/data/excelUpload.vue';
import exchangeRate from './components/data/exchangeRate/exchangeRate.vue';
import datadelete from './components/data/dataDelete.vue';
import scrap from './components/scrap/scrapPost.vue';
import scrapLog from './components/scrap/scrapLog.vue';
import scrapMeasurement from './components/scrap/scrapMeasurement/scrapMeasurement.vue';

import productionSpare from './components/stock/productionSpare/productionSpare.vue';

import checkTarget from './components/checkSheet/checkTarget/checkTarget.vue';
import checkMain from './components/checkSheet/checkMain/checkSheet.vue';
import masterSample from './components/checkSheet/masterSample/masterSample.vue';
import checkHistory from './components/checkSheet/checkSheetHistory/checkSheetHistory.vue';

import lpa from './components/LPA/lpaMain.vue';

import eolConnectorPin from './components/parameter/eol/eolConnectorPin.vue';
import smtLaser from './components/parameter/smtParameter/smtLaser/smtLaser.vue';
import bfaQrLaser from './components/parameter/bfaParameter/qrLaser/qrLaser.vue';
import bfaWeldLaser from './components/parameter/bfaParameter/weldLaser/weldLaser.vue';
import heatTipcleaning from './components/parameter/heatTipCleaning/heatTipCleaning.vue';
import calibrationDate from './components/parameter/calibrationDate/calibrationDate.vue';

import cleaningcycle from './components/5Scleaning/cleanTarget.vue';
import cleaningactual from './components/5Scleaning/cleaningList.vue';

import takeOver from './components/takeOver/takeOver.vue';
import peelTensile from './components/spcPeelTensile/spcPeelTensile.vue';

import inkatomSearch from './components/inkatom/inkatomSearch.vue';
import inkatomFirst from './components/inkatom/1stbfa/inkatomFirst.vue';
import inkatomFlex45 from './components/inkatom/1st45/inkatomFlex45.vue';
import inkatomFlex67 from './components/inkatom/1st67/inkatomFlex67.vue';
import inkatomFlexNe from './components/inkatom/1stnegative/inkatomFlexNe.vue';
import inkatomFlexPo from './components/inkatom/1stpositive/inkatomFlexPo.vue';
import inkatomSecond from './components/inkatom/2ndevo/inkatomSecond.vue';

import annualMaintenance from './components/maintenance/annualMaintenance/annualMaintenance.vue';
import maintenanceCheckTarget from './components/maintenance/maintenanceCheckSheet/checkTarget/mainCheckTarget.vue';
import maintenanceCheck from './components/maintenance/maintenanceCheckSheet/checkMain/checkSheet.vue';

import maintenanceWorkList from './components/maintenance/workList/maintenanceWorkList.vue';
import failureTarget from './components/maintenance/failureRate/failureTarget.vue';
import failureClass from './components/maintenance/failureRate/failureClass.vue';
import spareList from './components/maintenance/spareList/spareList.vue';
import equipmentLedger from './components/maintenance/equipmentLedger/equipmentLedger.vue';
import annualCalculation from './components/maintenance/annualMaintenance/annualCalculation.vue';
import machineGrade from './components/maintenance/machineGrade/machineGrade.vue';
import constructionInspection from './components/maintenance/constructionInspection/constructionInspection.vue';




import materialRequest from './components/materialRequest/materialRequest.vue';
import RequestLog from './components/materialRequest/RequestLog.vue';
import materialList from './components/materialRequest/materialList.vue';
import stockList from './components/stock/stockList.vue';
import notnormalstock from './components/stock/notNormalStock.vue';
import trayStock from './components/stock/trayStock.vue';
import dailyinventorycheck from './components/stock/dailyInventory/dailyInventory.vue';
import monthlyinventorycheck from './components/stock/monthlyInventory/inventoryCheck.vue';
import inOutMaterial from './components/stock/inOutMaterial/inOutMaterial.vue';
import movementlog from './components/stock/movement/movementLog.vue';
import barcodePrint from './components/stock/barcodePrint/barcodePrint.vue';
import barcodeRead from './components/stock/barcodePrint/barcodeRead.vue';


import productionPlan from './components/schedule/planning/week_Planning.vue';
import monthlySchedule from './components/schedule/schedule/monthlySchedule.vue';

import production1stAttendance from './components/attendance/production1st/production1stList.vue';
import production2ndAttendance from './components/attendance/production2nd/production2ndList.vue';
import qaAttendance from './components/attendance/qa/qaList.vue';
import warehouseAttendance from './components/attendance/warehouse/warehouseList.vue';
import inkatomAttendance from './components/attendance/inkatom/inkatomList.vue';
import searchAllAttendance from './components/attendance/searchAll.vue';
import weekendWork from './components/attendance/requestManPower/weekendWork.vue';
import lsevpDB from './components/attendance/db/lsevpDB.vue';
//import lscpDB from './components/attendance/db/lscpDB.vue';
import maxPowerDB from './components/attendance/db/maxPowerDB.vue';
import megaStaffDB from './components/attendance/db/megaStaffDB.vue';
import zxyDB from './components/attendance/db/zxy_DB.vue';
import inkatomDB from './components/attendance/db/inkatomDB.vue';
import monthlyMaxPower from './components/attendance/monthlyCost/monthlyMaxPower.vue';
import monthlyMegaStaff from './components/attendance/monthlyCost/monthlyMegaStaff.vue';
import monthlyZYX from './components/attendance/monthlyCost/monthlyZYX.vue';
import monthlyInkatom from './components/attendance/monthlyCost/monthlyInkatom.vue';
import monthlyLineCost from './components/attendance/monthlyCost/monthlyLine.vue';
//import lsAttendance from './components/attendance/ls/lsAttendance.vue';
import lineWorkerList from './components/attendance/lineWorkerList/lineWorkerList.vue';
import evaluationLine from './components/attendance/evaluation/evaluationLine.vue';
import evaluationPerson from './components/attendance/evaluation/evaluationPerson.vue';
import lsList from './components/attendance/ls/lsList.vue';

import mainDashBoard from './components/dashBoard/mainDashBoard.vue';

import toDoList from './components/toDoList/toDoList.vue';
import purchaseList from './components/purchaseList/purchaseList.vue';
import purchaseRequest from './components/purchaseList/purchaseRequest.vue';
import setting from './components/setting/settingList.vue';
import machineData from './components/machineData/machineData.vue';
import productionClass from './components/data/productionClass/productionClass.vue';
import selectOption from './components/data/selectOption/selectOption.vue';
import assetClass from './components/data/assetClass/assetClass.vue';
import attendanceProcess from './components/data/attendanceFetch/attendanceFetch.vue';
import arduino from './components/arduino/arduinoData.vue';
import arduinoLog from './components/arduino/arduinoLog.vue';
import stockPricePrediction from './components/data/stockPrice/stockPrice.vue';

import maxiStockList from './components/stock/maxiStockList/maxiStockList.vue';


import pFmea from './components/pFmea/pFmea.vue';
import controlPlan from './components/controlPlan/controlPlan.vue';
import flowChart from './components/flowChart/flowChart.vue';

import loginLog from './components/data/loginLog/loginLog.vue';

const kId = [2, 34, 35, 36, 37, 38, 39, 41, 42, 43, 44]; //한국사람 ID
const poId = [3, 40] //Poland Engineer ID
const p1Id = [4, 6, 14, 15, 17, 21, 23, 25, 27, 32]; //생산1공장 ID
const p2Id = [5, 7, 13, 14, 16, 17, 18, 20, 22, 24, 26, 27, 32]; //생산2공장 ID
const suId = [4, 5] //super Visor ID
const mId = [20, 31] //maintenance ID

const routes = [  
  { path: '/tr2smtpr', component: Tr2smtPr, meta: { allowedIds: [1, ...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/tr2fpcapr', component: Tr2fpcaPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/tr2crimpingpr', component: Tr2crimpingPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/tr2bfa2ppr', component: Tr2bfa2pPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, ...p2Id], currentUser: null } },  
  { path: '/tr2bfa3ppr', component: Tr2bfa3pPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/ford1frpr', component: Ford1frPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/ford1rrpr', component: Ford1rrPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/ford2frpr', component: Ford2frPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/ford2rrpr', component: Ford2rrPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/ford3frpr', component: Ford3frPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/ford3rrpr', component: Ford3rrPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/fca1pr', component: Fca1Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, ...poId], currentUser: null } },  
  { path: '/fca2pr', component: Fca2Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, ...poId], currentUser: null } },  
  { path: '/bt6pr', component: Bt6Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/bmw12vpr', component: Bmw12vPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },  
  { path: '/bmw48vpr', component: Bmw48vPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id, ...p2Id], currentUser: null } },    
  { path: '/fcaflexbcpr', component: FcaFlexBcPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },    
  { path: '/fcaflexpopr', component: FcaFlexPoPr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },   
  { path: '/fcaflex67pr', component: FcaFlex67Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },   
  { path: '/fcaflexnepr', component: FcaFlexNePr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } },   
  { path: '/fcaflex45pr', component: FcaFlex45Pr, meta: { allowedIds: [1,...kId, ...poId, ...p1Id], currentUser: null } }, 

  { path: '/tr3smtpr', component: Tr3smtPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },  
  { path: '/tr3crimpingpr', component: Tr3crimpingPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },    
  { path: '/tr3fpcapr', component: Tr3fpcaPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },  
  { path: '/tr3bfa2ppr', component: Tr3bfa2pPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },  
  { path: '/tr3bfa3ppr', component: Tr3bfa3pPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },  
  { path: '/evopr', component: EvoPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },   
  { path: '/thermistortape', component: thermistorTape, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },   
  { path: '/thermistorpad', component: thermistorPad, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },   
  { path: '/bendingevo', component: bendingEvo, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },   
  { path: '/ev202060frpr', component: Ev2020_60FrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },    
  { path: '/ev202060rrpr', component: Ev2020_60RrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } }, 
  { path: '/ev20204060frpr', component: Ev2020_4060FrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },  
  { path: '/ev20204060rrpr', component: Ev2020_4060RrPr, meta: { allowedIds: [1, ...kId, ...poId, ...p2Id], currentUser: null } },  

  { path: '/packingpr', component: packingPr, meta: { allowedIds: [1, ...kId, ...poId, ...p1Id, ...p2Id, ...suId], currentUser: null } }, 

  { path: '/resultverification', component: resultVerification, meta: { allowedIds: [1, ...kId, ...poId, ...p1Id, ...p2Id], currentUser: null } },  

  { path: '/searchPeriod', component: searchPeriod, meta: { allowedIds: [1, ...kId, ...poId, ...suId, 14], currentUser: null } },  
  { path: '/report01', component: report01, meta: { allowedIds: [1, ...kId, ...poId, ...suId, 14, 17 ,18, 27], currentUser: null } },
  { path: '/report02', component: report02, meta: { allowedIds: [1, ...kId, ...poId, 14, 17 ,18, 27], currentUser: null } },
  { path: '/monthlyreport', component: monthlyReport, meta: { allowedIds: [1, ...kId, ...poId, 14, 17 ,18, 27], currentUser: null } },
  { path: '/oscost', component: osCost, meta: { allowedIds: [1, ], currentUser: null } },
  { path: '/failurereport', component: failureReport, meta: { allowedIds: [1,...kId,...poId, ...mId ], currentUser: null } },  
  { path: '/correctiveaction', component: correctiveAction, meta: { allowedIds: [1, ...kId, ...poId, 14, 17 ,18, 27], currentUser: null } },  
  { path: '/investlist', component: investList, meta: { allowedIds: [1, ...kId, ...poId ], currentUser: null } },  
  { path: '/assetlist', component: assetList, meta: { allowedIds: [1, ...kId, ...poId, ], currentUser: null } },    
  { path: '/documentreport', component: documentReport, meta: { allowedIds: [1, ...kId, ...poId, ], currentUser: null } },  


  { path: '/shipping', component: shippingAllProject, meta: { allowedIds: [1,...kId,...poId,...suId, 11, 14 ,18], currentUser: null } },  

  { path: '/targetList', component: targetList, meta: { allowedIds: [1,...kId,...poId, 14], currentUser: null } },
  { path: '/ngList', component: ngList, meta: { allowedIds: [1,...kId,...poId, 14], currentUser: null } },
  { path: '/excelupload', component: excelupload, meta: { allowedIds: [1,...kId,...poId], currentUser: null } },
  { path: '/exchangerate', component: exchangeRate, meta: { allowedIds: [1,...kId,...poId], currentUser: null } },
  { path: '/datadelete', component: datadelete, meta: { allowedIds: [1,...kId,...poId], currentUser: null } },
  { path: '/scrap', component: scrap, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18], currentUser: null } },
  { path: '/scraplog', component: scrapLog, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18], currentUser: null } },
  { path: '/scrapmeasurement', component: scrapMeasurement, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18], currentUser: null } },
  
  { path: '/checktarget', component: checkTarget, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11], currentUser: null } },  
  { path: '/checkmain/:lineName', name: 'checkMain', component: checkMain, props: true, meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },
  { path: '/checkhistory/:type', name: 'checkHistory', component: checkHistory,  props: route => ({ type: route.params.type, headName: route.query.headName }), meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },
  
  { path: '/masterSample', component: masterSample, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32], currentUser: null } },

  { path: '/lpa', component: lpa, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 15, 32], currentUser: null } },
  
  { path: '/cleaningcycle1st/:dbTableName', name: 'cleaningcycle1st', component: cleaningcycle,  props: route => ({ dbTableName: route.params.dbTableName }), meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },
  { path: '/cleaningactual1st/:dbTableName', name: 'cleaningactual1st', component: cleaningactual,  props: route => ({ dbTableName: route.params.dbTableName, cycleTableName: route.query.cycleTableName }), meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },
  { path: '/cleaningcycle2nd/:dbTableName', name: 'cleaningcycle2nd', component: cleaningcycle,  props: route => ({ dbTableName: route.params.dbTableName }), meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },
  { path: '/cleaningactual2nd/:dbTableName', name: 'cleaningactual2nd', component: cleaningactual,  props: route => ({ dbTableName: route.params.dbTableName, cycleTableName: route.query.cycleTableName }), meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },

  { path: '/takeover', component: takeOver, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },
  { path: '/peeltensile', component: peelTensile, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },  

  { path: '/eolConnectorPin', component: eolConnectorPin, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18], currentUser: null } },
  { path: '/smtlaser', component: smtLaser, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18], currentUser: null } },
  { path: '/bfaqrlaser', component: bfaQrLaser, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18], currentUser: null } },
  { path: '/bfaweldlaser', component: bfaWeldLaser, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18], currentUser: null } },
  { path: '/heattipcleaning', component: heatTipcleaning, meta: { allowedIds: [1,...kId,...poId, 7, 15, 16, 17, 18], currentUser: null } },  
  { path: '/calibrationDate', component: calibrationDate, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 15, 16, 17, ...mId], currentUser: null } },
  
  { path: '/inkatomSearch', component: inkatomSearch, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
  { path: '/inkatomFirst', component: inkatomFirst, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
  { path: '/inkatom45', component: inkatomFlex45, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
  { path: '/inkatom67', component: inkatomFlex67, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
  { path: '/inkatomNe', component: inkatomFlexNe, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
  { path: '/inkatomPo', component: inkatomFlexPo, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
  { path: '/inkatomSecond', component: inkatomSecond, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 19, 32], currentUser: null } },
      
  { path: '/annualmaintenance', component: annualMaintenance, meta: { allowedIds: [1,...kId,...poId, ...mId], currentUser: null } },
  { path: '/maintenancechecktarget', component: maintenanceCheckTarget, meta: { allowedIds: [1,...kId,...poId, ...mId], currentUser: null } },
  
  { path: '/maintenanceCheck/:lineName', name: 'maintenanceCheck', component: maintenanceCheck, props: true, meta: { allowedIds: [1,...kId,...poId, ...mId], currentUser: null } },
  { path: '/maintenanceCheckHistory/:type', name: 'maintenanceCheckHistory', component: checkHistory,  props: route => ({ type: route.params.type, headName: route.query.headName }), meta: { allowedIds: [1, ...kId, ...poId, ...suId, 6, 7, 8, 9, 11, 16, 32], currentUser: null } },

  { path: '/maintenanceworklist', component: maintenanceWorkList, meta: { allowedIds: [1,...kId,...poId, ...mId ], currentUser: null } },
  { path: '/failuretarget', component: failureTarget, meta: { allowedIds: [1,...kId,...poId, ...mId ], currentUser: null } },
  { path: '/failureclass', component: failureClass, meta: { allowedIds: [1,...kId,...poId, ...mId ], currentUser: null } },  
  { path: '/spareList', component: spareList, meta: { allowedIds: [1,...kId,...poId, ...mId, 32 ], currentUser: null } },  
  { path: '/equipmentledger', component: equipmentLedger, meta: { allowedIds: [1,...kId,...poId, ...mId, 32], currentUser: null } },  
  { path: '/annualcalculation', component: annualCalculation, meta: { allowedIds: [1,...kId,...poId, ...mId, 32], currentUser: null } },  
  { path: '/machinegrade', component: machineGrade, meta: { allowedIds: [1,...kId,...poId, ...mId, 32], currentUser: null } },  
  { path: '/constructioninspection', component: constructionInspection, meta: { allowedIds: [1,...kId,...poId, ...mId, 32], currentUser: null } },  

      

  { path: '/materialrequest', component: materialRequest, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 11, 12, 13, 14, 17 ,18], currentUser: null } },  
  { path: '/requestlog', component: RequestLog, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 11, 12, 13, 14, 17 ,18], currentUser: null } },  
  { path: '/materiallist', component: materialList, meta: { allowedIds: [1,...kId,...poId, ...suId, 11, 12, 13, 14 ,18], currentUser: null } },  
    
  { path: '/stockList', component: stockList, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 21, 22, 23, 24, 25, 26], currentUser: null } },  
  { path: '/notnormalstock', component: notnormalstock, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26], currentUser: null } },  
  { path: '/trayStock', component: trayStock, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18,21, 22, 23, 24, 25, 26], currentUser: null } },  

  { path: '/barcodeprint', component: barcodePrint, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 21, 22, 23, 24, 25, 26], currentUser: null } },  
  { path: '/barcoderead', component: barcodeRead, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17, 18, 21, 22, 23, 24, 25, 26 ], currentUser: null } },  

  { path: '/dailyinventorycheck', component: dailyinventorycheck, meta: { allowedIds: [1,], currentUser: null } },  
  { path: '/monthlyinventorycheck', component: monthlyinventorycheck, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26], currentUser: null } },  
  { path: '/movementlog', component: movementlog, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26], currentUser: null } },  
  { path: '/productionspare', component: productionSpare, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 11, 12, 13, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 32], currentUser: null } },  
  
  { path: '/productionplan', component: productionPlan, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ,18, 21, 22, 23, 24, 25, 26, 27, 32], currentUser: null } },  
  { path: '/monthlyschedule', component: monthlySchedule, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ,18, 21, 22, 23, 24, 25, 26, 27, 32], currentUser: null } },  

  { path: '/production1stattendance', component: production1stAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },    
  { path: '/production2ndattendance', component: production2ndAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },    
  { path: '/qaattendance', component: qaAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },  
  { path: '/warehouseattendance', component: warehouseAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },  
  { path: '/inkatomattendance', component: inkatomAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },  
  { path: '/attendancesearchall', component: searchAllAttendance, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },    
  { path: '/weekendWork', component: weekendWork, meta: { allowedIds: [1,...kId,...poId, ...suId, 6, 7, 8, 9, 10, 11, 13, 14, 17, 18, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },    
  { path: '/lsevpdb', component: lsevpDB, meta: { allowedIds: [1,...kId,...poId, ...mId, 14, 28, 31, 32], currentUser: null } },    
  //{ path: '/lscpdb', component: lscpDB, meta: { allowedIds: [1,...kId, 28], currentUser: null } },    
  { path: '/maxpowerdb', component: maxPowerDB, meta: { allowedIds: [1,...kId,...poId, 8, 14, 28], currentUser: null } },    
  { path: '/megastaffdb', component: megaStaffDB, meta: { allowedIds: [1,...kId,...poId, 9, 14, 28], currentUser: null } },    
  { path: '/zxydb', component: zxyDB, meta: { allowedIds: [1,...kId,...poId, 10, 14, 28], currentUser: null } },    
  { path: '/inkatomdb', component: inkatomDB, meta: { allowedIds: [1,...kId,...poId, 19, 14, 28], currentUser: null } },    
  { path: '/monthlymaxpower', component: monthlyMaxPower, meta: { allowedIds: [1,...kId,...poId, 8, 14, 28], currentUser: null } },    
  { path: '/monthlymegastaff', component: monthlyMegaStaff, meta: { allowedIds: [1,...kId,...poId, 9, 14, 28], currentUser: null } },    
  { path: '/monthlyzyx', component: monthlyZYX, meta: { allowedIds: [1,...kId,...poId, 10, 14, 28], currentUser: null } },    
  { path: '/monthlyinkatom', component: monthlyInkatom, meta: { allowedIds: [1,...kId,...poId, 19, 14, 28], currentUser: null } },    
  { path: '/monthlylinecost', component: monthlyLineCost, meta: { allowedIds: [1,...kId,...poId, 14, 28], currentUser: null } },    
  //{ path: '/lsattendance', component: lsAttendance, meta: { allowedIds: [1,...kId,...poId, 28, 29], currentUser: null } },    
  { path: '/lineworkerlist', component: lineWorkerList, meta: { allowedIds: [1,...kId,...poId, ...mId, 8, 9, 10, 14, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },
  { path: '/evaluationline', component: evaluationLine, meta: { allowedIds: [1,...kId,...poId, ...mId, 8, 9, 10, 14, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },
  { path: '/evaluationperson', component: evaluationPerson, meta: { allowedIds: [1,...kId,...poId, ...mId, 8, 9, 14, 10, 19, 21, 22, 23, 24, 25, 26, 28, 32], currentUser: null } },
  { path: '/lslist', component: lsList, meta: { allowedIds: [1,...kId,...poId, 28], currentUser: null } },    

  { path: '/dashboard', component: mainDashBoard, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 6, 7, 8, 9, 11, 14, 17 ,18, 21, 22, 23, 24, 25, 26, 27, 30, 32], currentUser: null } },      
  { path: '/todolist', component: toDoList, meta: { allowedIds: [1,...kId,...poId ], currentUser: null } },    
  { path: '/purchaselist', component: purchaseList, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 11, 12, 13, 14, 15, 16, 17, 18, 28, 32], currentUser: null } },    
  { path: '/purchaserequest', component: purchaseRequest, meta: { allowedIds: [1,...kId,...poId, ...suId, ...mId, 11, 12, 13, 14, 15, 16, 17, 18, 28, 32], currentUser: null } },    
  { path: '/machinedata', component: machineData, meta: { allowedIds: [1, ...kId,...poId, 17, 32], currentUser: null } }, 
  { path: '/productionclass', component: productionClass, meta: { allowedIds: [1, ...kId,...poId, 17, 32], currentUser: null } }, 
  { path: '/selectoption', component: selectOption, meta: { allowedIds: [1, ...kId, ...poId, 17, 32], currentUser: null } }, 
  { path: '/assetclass', component: assetClass, meta: { allowedIds: [1, ...kId, ...poId, 17, 32], currentUser: null } }, 
  { path: '/attendanceprocess', component: attendanceProcess, meta: { allowedIds: [1, ...kId, ...poId, 17, 32], currentUser: null } }, 
  { path: '/setting', component: setting, meta: { allowedIds: [1 ], currentUser: null } },    
  { path: '/arduino', component: arduino, meta: { allowedIds: [1, ...kId, ...poId], currentUser: null } },      
  { path: '/arduinolog', component: arduinoLog, meta: { allowedIds: [1, ...kId, ...poId], currentUser: null } }, 
  { path: '/stockpriceprediction', component: stockPricePrediction, meta: { allowedIds: [1, 2 ], currentUser: null } },
  { path: '/inoutmaterial', component: inOutMaterial, meta: { allowedIds: [1 ], currentUser: null } },      
  { path: '/maxistocklist', component: maxiStockList, meta: { allowedIds: [1 ], currentUser: null } },      

  { path: '/pfmea', component: pFmea, meta: { allowedIds: [1, ...kId, ...poId, 32 ], currentUser: null } },      
  { path: '/controlplan', component: controlPlan, meta: { allowedIds: [1, ...kId, ...poId, 32 ], currentUser: null } },      
  { path: '/flowchart', component: flowChart, meta: { allowedIds: [1, ...kId, ...poId, 32 ], currentUser: null } },      

  { path: '/loginLog', component: loginLog, meta: { allowedIds: [1 ], currentUser: null } },      
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function getUserID() {
  const token = Cookies.get('token'); 

  if (token) {
    try {
      const decodedToken = jwtDecode(token);      
      return decodedToken.id;
    } catch (error) {
      console.error('Error decoding JWT token:', error);     
    }
  }

  return null;
}
async function getCurrentUser() {
  try {
    const response = await axios.get('/api/accountGet');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {         
      return null;
    } else {
      console.error('Error fetching current user:', error);
      return null;
    }
  }
}

router.beforeEach(async (to, from, next) => {  
  const userId = getUserID(); 
  const currentUser = await getCurrentUser();
  if (to.meta) {
    to.meta.currentUser = currentUser;
  }
  if (to.meta.allowedIds && !to.meta.allowedIds.includes(userId)) {  
    next('/error');
  } else {
    next();
  }

});

export default router;
