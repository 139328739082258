<template>
    <div class="tr3smt">            
      <div class="act">               
         <h3 class="pagetitle">TR3 SMT</h3>        
      </div>    
      <form @submit.prevent="sendDate()" class="searchBar">     
        <select id="year" v-model="searchDate.year">   
            <option disabled selected value="" style="color: gray;">Year</option>           
            <option>2021</option><option>2022</option><option>2023</option><option>2024</option>
            <option>2025</option><option>2026</option><option>2027</option><option>2028</option>
            <option>2029</option><option>2030</option><option>2031</option><option>2032</option>
            <option>2033</option><option>2034</option><option>2035</option><option>2036</option>
            <option>2037</option><option>2038</option><option>2039</option><option>2040</option>
        </select>           
        <select id="month" v-model="searchDate.month">            
            <option disabled selected value="" style="color: gray;">Month</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option>            
        </select>           
        <select id="day" v-model="searchDate.day">            
            <option disabled selected value="" style="color: gray;">Day</option>
            <option>1</option><option>2</option><option>3</option><option>4</option>
            <option>5</option><option>6</option><option>7</option><option>8</option>
            <option>9</option><option>10</option><option>11</option><option>12</option> 
            <option>13</option><option>14</option><option>15</option><option>16</option>   
            <option>17</option><option>18</option><option>19</option><option>20</option>   
            <option>21</option><option>22</option><option>23</option><option>24</option>
            <option>25</option><option>26</option><option>27</option><option>28</option>
            <option>29</option><option>30</option><option>31</option>                    
        </select>
        <button class="searchButton" type="submit"  @click="callSearchData">Search</button>          
        <button class="excelButton" @click="downExcel">Excel</button>
        <button class="button" @click="showModal = true">+ Result</button>
        <button class="button" @click="showdtModal = true">+ Downtime</button>
        <button class="button" @click="showdrModal = true">+ Daily Report</button>
        <button class="qwallButton" @click="showqwModal = true">+ Q-wall</button>      
        <button class="button" @click="showrdModal = true">+ Repeat Data</button>
      </form>
      <br>
      <div class="tables">
      <table id="productionResult" class="custom-table">
        <tr>          
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>          
        </tr>          
          <tr v-for="row in item" :key="row.id">
            <template v-for="(header, index) in headers">  <!-- 설비에 따라 header가 바뀌면 여기서 해당 header를 읽어 data를 가져오게 되어 있음 -->
                <td v-if="header === 'Shift'" :key="index" @click="editCell(dbTableName, row.id, 'shift', $event)"  :class="{'shift1': row.shift === 1, 'shift2': row.shift === 2}">{{ row.shift }}</td>
                <td v-if="header === 'Time'" :key="index" class="sticky-time">{{ row.start_time }}~{{ row.end_time }}</td>
                <td v-if="header === 'Model'" :key="index" :class="{ 'model-2P12S': row.model === '2P12S', 'model-3P8S': row.model === '3P8S', 'model-evo2p': row.model === 'EVO_2P', 'model-evo3p': row.model === 'EVO_3P', 'model-mc253p': row.model === 'MC25_3P'}" @click="editCell(dbTableName, row.id, 'model', $event)">{{ row.model }}</td>
                <td v-if="header === 'Target'" :key="index" @click="editCell(dbTableName, row.id, 'target', $event)">{{ row.target }}</td>
                <td v-if="header === 'Input'" :key="index" >{{ row.input }}</td>
                <td v-if="header === 'OK'" :key="index" class="ok" @click="editCell(dbTableName, row.id, 'ok', $event)">{{ row.ok }}</td>
                <td v-if="header === 'NG'" :key="index" class="ng" :class="{ 'zero-value_ng': row.ng === 0 }" @click="editCell(dbTableName, row.id, 'ng', $event)">{{ row.ng }}</td>
                <td v-if="header === 'Fuse'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_fuse === 0 }" @click="editCell(dbTableName, row.id, 'ng_fuse', $event)">{{ row.ng_fuse }}</td>
                <td v-if="header === 'Thermistor'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_thermistor === 0 }" @click="editCell(dbTableName, row.id, 'ng_thermistor', $event)">{{ row.ng_thermistor }}</td>
                <td v-if="header === 'Fiducial'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_fiducial === 0 }" @click="editCell(dbTableName, row.id, 'ng_fiducial', $event)">{{ row.ng_fiducial }}</td>
                <td v-if="header === 'QR-Code'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_qrcode === 0 }" @click="editCell(dbTableName, row.id, 'ng_qrcode', $event)">{{ row.ng_qrcode }}</td>
                <td v-if="header === 'Burned'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_burned === 0 }" @click="editCell(dbTableName, row.id, 'ng_burned', $event)">{{ row.ng_burned }}</td>
                <td v-if="header === 'Black Fuse'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_blackfuse === 0 }" @click="editCell(dbTableName, row.id, 'ng_blackfuse', $event)">{{ row.ng_blackfuse }}</td>
                <td v-if="header === 'Others'" :key="index" class="ng_type" :class="{ 'zero-value_ngtype': row.ng_others === 0 }" @click="editCell(dbTableName, row.id, 'ng_others', $event)">{{ row.ng_others }}</td>
                <td v-if="header === 'NG Rate'" :key="index" class="ng" >{{ row.ngRate }}%</td> <!--made by kwon-->
                <td v-if="header === 'Fake Sum'" :key="index" class="fn" :class="{ 'zero-value_fn': row.fakeng === 0 }">{{ row.fakeng }}</td> <!--need to make cal-->
                <td v-if="header === 'S-AOI Fuse'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_saoi_fuse === 0 }" @click="editCell(dbTableName, row.id, 'fn_saoi_fuse', $event)">{{ row.fn_saoi_fuse }}</td> 
                <td v-if="header === 'S-AOI Thermistor'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_saoi_thermistor === 0 }" @click="editCell(dbTableName, row.id, 'fn_saoi_thermistor', $event)">{{ row.fn_saoi_thermistor }}</td>
                <td v-if="header === 'Final Inspection'" :key="index" class="fn_type" :class="{ 'zero-value_fntype': row.fn_finalinspection === 0 }" @click="editCell(dbTableName, row.id, 'fn_finalinspection', $event)">{{ row.fn_finalinspection }}</td>
                <td v-if="header === 'Fake NG Rate'" :key="index" class="fn" >{{ row.fn_ngRate }}%</td> <!--need to make cal-->
                <td v-if="header === 'FPCB Block'" :key="index" class="semi" :class="{ 'zero-value_semi': row.semi_fpcbblock === 0 }" @click="editCell(dbTableName, row.id, 'semi_fpcbblock', $event)">{{ row.semi_fpcbblock }}</td>
                <td v-if="header === 'WEEK CODE'" :key="index" @click="editCell(dbTableName, row.id, 'weekcode', $event)">{{ row.weekcode }}</td> 
                <td v-if="header === 'WEEK TIN PLATING'" :key="index" @click="editCell(dbTableName, row.id, 'weektinplating', $event)">{{ row.weektinplating }}</td> 
                <td v-if="header === 'Work Order'" :key="index" @click="editCell(dbTableName, row.id, 'workorder', $event)">{{ row.workorder }}</td> 
                <td v-if="header === 'Supervisor'" :key="index" @click="editCell(dbTableName, row.id, 'supervisor', $event)">{{ row.supervisor }}</td> 
                <td v-if="header === 'Leader'" :key="index" @click="editCell(dbTableName, row.id, 'leader', $event)">{{ row.leader }}</td> 
                <td v-if="header === 'Technician'" :key="index" @click="editCell(dbTableName, row.id, 'technician', $event)">{{ row.technician }}</td> 
                <td v-if="header === 'Shift Name'" :key="index" @click="editCell(dbTableName, row.id, 'shift_name', $event)">{{ row.shift_name }}</td> 
                <td v-if="header === 'Operator Quantity'" :key="index" @click="editCell(dbTableName, row.id, 'operator_qty', $event)">{{ row.operator_qty }}</td> 
                <td v-if="header === 'Work Time'" :key="index" @click="editCell(dbTableName, row.id, 'work_minute', $event)">{{ row.work_minute }}</td> 
                <td v-if="header === 'row_no'" :key="index" @click="editCell(dbTableName, row.id, 'row_no', $event)">{{ row.row_no }}</td>                
            </template>
        </tr>
        <tr>
         <th rowspan="2">Shift</th>
         <td>1</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[1].target }}</td>
          <td>{{ sums.shiftSums[1].input }}</td>
          <td>{{ sums.shiftSums[1].ok }}</td>
          <td>{{ sums.shiftSums[1].ng }}</td>
          <td>{{ sums.shiftSums[1].ng_fuse }}</td>
          <td>{{ sums.shiftSums[1].ng_thermistor }}</td>
          <td>{{ sums.shiftSums[1].ng_fiducial }}</td>
          <td>{{ sums.shiftSums[1].ng_qrcode }}</td>
          <td>{{ sums.shiftSums[1].ng_burned }}</td>
          <td>{{ sums.shiftSums[1].ng_blackfuse }}</td>
          <td>{{ sums.shiftSums[1].ng_others }}</td>          
          <td>{{ ((sums.shiftSums[1].ng) / (sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[1].fn_saoi_fuse + sums.shiftSums[1].fn_saoi_thermistor + sums.shiftSums[1].fn_finalinspection }}</td>
          <td>{{ sums.shiftSums[1].fn_saoi_fuse }}</td>
          <td>{{ sums.shiftSums[1].fn_saoi_thermistor }}</td>
          <td>{{ sums.shiftSums[1].fn_finalinspection }}</td>
          <td>{{ ((sums.shiftSums[1].fn_saoi_fuse + sums.shiftSums[1].fn_saoi_thermistor + sums.shiftSums[1].fn_finalinspection)/(sums.shiftSums[1].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Subtotal</td>
          <td>{{ sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[2].input }}</td>
          <td>{{ sums.shiftSums[2].ok }}</td>
          <td>{{ sums.shiftSums[2].ng }}</td>
          <td>{{ sums.shiftSums[2].ng_fuse }}</td>
          <td>{{ sums.shiftSums[2].ng_thermistor }}</td>
          <td>{{ sums.shiftSums[2].ng_fiducial }}</td>
          <td>{{ sums.shiftSums[2].ng_qrcode }}</td>
          <td>{{ sums.shiftSums[2].ng_burned }}</td>
          <td>{{ sums.shiftSums[2].ng_blackfuse }}</td>
          <td>{{ sums.shiftSums[2].ng_others }}</td>          
          <td>{{ ((sums.shiftSums[2].ng) / (sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.shiftSums[2].fn_saoi_fuse + sums.shiftSums[2].fn_saoi_thermistor + sums.shiftSums[2].fn_finalinspection }}</td>
          <td>{{ sums.shiftSums[2].fn_saoi_fuse }}</td>
          <td>{{ sums.shiftSums[2].fn_saoi_thermistor }}</td>
          <td>{{ sums.shiftSums[2].fn_finalinspection }}</td>
          <td>{{ ((sums.shiftSums[2].fn_saoi_fuse + sums.shiftSums[2].fn_saoi_thermistor + sums.shiftSums[2].fn_finalinspection)/(sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th rowspan="5">Model</th>
          <td>2P12S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['2P12S'].target }}</td>
          <td>{{ sums.modelSums['2P12S'].input }}</td>
          <td>{{ sums.modelSums['2P12S'].ok - sums.qwmodelSums['2P12S'].ng}}</td>
          <td>{{ sums.modelSums['2P12S'].ng + sums.qwmodelSums['2P12S'].ng }}</td>
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td> <!-- obj.ng_fuse 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td>{{ [ sums.modelSums['2P12S'],sums.qwmodelSums['2P12S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ ((sums.modelSums['2P12S'].ng + sums.qwmodelSums['2P12S'].ng) / (sums.modelSums['2P12S'].input) * 100).toFixed(1) }}%</td>
          <td>{{ sums.modelSums['2P12S'].fn_saoi_fuse + sums.modelSums['2P12S'].fn_saoi_thermistor + sums.modelSums['2P12S'].fn_finalinspection }}</td>
          <td>{{ sums.modelSums['2P12S'].fn_saoi_fuse }}</td>
          <td>{{ sums.modelSums['2P12S'].fn_saoi_thermistor }}</td>
          <td>{{ sums.modelSums['2P12S'].fn_finalinspection }}</td>
          <td>{{ ((sums.modelSums['2P12S'].fn_saoi_fuse + sums.modelSums['2P12S'].fn_saoi_thermistor + sums.modelSums['2P12S'].fn_finalinspection)/(sums.modelSums['2P12S'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>3P8S</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['3P8S'].target }}</td>
          <td>{{ sums.modelSums['3P8S'].input }}</td>
          <td>{{ sums.modelSums['3P8S'].ok - sums.qwmodelSums['3P8S'].ng }}</td>
          <td>{{ sums.modelSums['3P8S'].ng + sums.qwmodelSums['3P8S'].ng}}</td>
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td> <!-- obj.ng_fuse 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td>{{ [ sums.modelSums['3P8S'],sums.qwmodelSums['3P8S']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ ((sums.modelSums['3P8S'].ng + sums.qwmodelSums['3P8S'].ng) / (sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['3P8S'].fn_saoi_fuse + sums.modelSums['3P8S'].fn_saoi_thermistor + sums.modelSums['3P8S'].fn_finalinspection }}</td>
          <td>{{ sums.modelSums['3P8S'].fn_saoi_fuse }}</td>
          <td>{{ sums.modelSums['3P8S'].fn_saoi_thermistor }}</td>
          <td>{{ sums.modelSums['3P8S'].fn_finalinspection }}</td>
          <td>{{ ((sums.modelSums['3P8S'].fn_saoi_fuse + sums.modelSums['3P8S'].fn_saoi_thermistor + sums.modelSums['3P8S'].fn_finalinspection)/(sums.modelSums['3P8S'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>EVO_2P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_2P'].target }}</td>
          <td>{{ sums.modelSums['EVO_2P'].input }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ok - sums.qwmodelSums['EVO_2P'].ng }}</td>
          <td>{{ sums.modelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_2P'].ng}}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td> <!-- obj.ng_fuse 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_2P'],sums.qwmodelSums['EVO_2P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ ((sums.modelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_2P'].ng) / (sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['EVO_2P'].fn_saoi_fuse + sums.modelSums['EVO_2P'].fn_saoi_thermistor + sums.modelSums['EVO_2P'].fn_finalinspection }}</td>
          <td>{{ sums.modelSums['EVO_2P'].fn_saoi_fuse }}</td>
          <td>{{ sums.modelSums['EVO_2P'].fn_saoi_thermistor }}</td>
          <td>{{ sums.modelSums['EVO_2P'].fn_finalinspection }}</td>
          <td>{{ ((sums.modelSums['EVO_2P'].fn_saoi_fuse + sums.modelSums['EVO_2P'].fn_saoi_thermistor + sums.modelSums['EVO_2P'].fn_finalinspection)/(sums.modelSums['EVO_2P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>EVO_3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['EVO_3P'].target }}</td>
          <td>{{ sums.modelSums['EVO_3P'].input }}</td>
          <td>{{ sums.modelSums['EVO_3P'].ok - sums.qwmodelSums['EVO_3P'].ng }}</td>
          <td>{{ sums.modelSums['EVO_3P'].ng + sums.qwmodelSums['EVO_3P'].ng}}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td> <!-- obj.ng_fuse 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td>{{ [ sums.modelSums['EVO_3P'],sums.qwmodelSums['EVO_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ ((sums.modelSums['EVO_3P'].ng + sums.qwmodelSums['EVO_3P'].ng) / (sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['EVO_3P'].fn_saoi_fuse + sums.modelSums['EVO_3P'].fn_saoi_thermistor + sums.modelSums['EVO_3P'].fn_finalinspection }}</td>
          <td>{{ sums.modelSums['EVO_3P'].fn_saoi_fuse }}</td>
          <td>{{ sums.modelSums['EVO_3P'].fn_saoi_thermistor }}</td>
          <td>{{ sums.modelSums['EVO_3P'].fn_finalinspection }}</td>
          <td>{{ ((sums.modelSums['EVO_3P'].fn_saoi_fuse + sums.modelSums['EVO_3P'].fn_saoi_thermistor + sums.modelSums['EVO_3P'].fn_finalinspection)/(sums.modelSums['EVO_3P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
          <td>MC25_3P</td>
          <td>Subtotal</td>
          <td>{{ sums.modelSums['MC25_3P'].target }}</td>
          <td>{{ sums.modelSums['MC25_3P'].input }}</td>
          <td>{{ sums.modelSums['MC25_3P'].ok - sums.qwmodelSums['MC25_3P'].ng }}</td>
          <td>{{ sums.modelSums['MC25_3P'].ng + sums.qwmodelSums['MC25_3P'].ng}}</td>
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td> <!-- obj.ng_fuse 부분을 수정하면 된다.-->          
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td>
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td>
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td>
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td>
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td>
          <td>{{ [ sums.modelSums['MC25_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>
          <td>{{ ((sums.modelSums['MC25_3P'].ng + sums.qwmodelSums['MC25_3P'].ng) / (sums.modelSums['MC25_3P'].input) * 100).toFixed(1) }}%</td>          
          <td>{{ sums.modelSums['MC25_3P'].fn_saoi_fuse + sums.modelSums['MC25_3P'].fn_saoi_thermistor + sums.modelSums['MC25_3P'].fn_finalinspection }}</td>
          <td>{{ sums.modelSums['MC25_3P'].fn_saoi_fuse }}</td>
          <td>{{ sums.modelSums['MC25_3P'].fn_saoi_thermistor }}</td>
          <td>{{ sums.modelSums['MC25_3P'].fn_finalinspection }}</td>
          <td>{{ ((sums.modelSums['MC25_3P'].fn_saoi_fuse + sums.modelSums['MC25_3P'].fn_saoi_thermistor + sums.modelSums['MC25_3P'].fn_finalinspection)/(sums.modelSums['MC25_3P'].input) * 100).toFixed(1) }}%</td>
        </tr>
        <tr>
         <th colspan="3">SMT Total</th>         
          <td>{{ sums.shiftSums[1].target + sums.shiftSums[2].target }}</td>
          <td>{{ sums.shiftSums[1].input + sums.shiftSums[2].input }}</td>
          <td class="ok" >{{ sums.shiftSums[1].ok + sums.shiftSums[2].ok - sums.qwmodelSums['2P12S'].ng - sums.qwmodelSums['3P8S'].ng - sums.qwmodelSums['EVO_2P'].ng - sums.qwmodelSums['EVO_3P'].ng - sums.qwmodelSums['MC25_3P'].ng}}</td>
          <td class="ng" >{{ sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['2P12S'].ng + sums.qwmodelSums['3P8S'].ng + sums.qwmodelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_3P'].ng + sums.qwmodelSums['MC25_3P'].ng}}</td>
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_fuse, 0) }}</td> <!-- obj.ng_fuse 부분을 수정하면 된다.-->          
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_thermistor, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_fiducial, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_qrcode, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_burned, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_blackfuse, 0) }}</td> 
          <td class="ng_type">{{ [ sums.shiftSums[1],sums.shiftSums[2],sums.qwmodelSums['2P12S'],sums.qwmodelSums['3P8S'],sums.qwmodelSums['EVO_2P'],sums.qwmodelSums['EVO_3P'],sums.qwmodelSums['MC25_3P']].reduce((sum, obj) => sum + obj.ng_others, 0) }}</td>     
          <td class="ng">{{ ((sums.shiftSums[1].ng + sums.shiftSums[2].ng + sums.qwmodelSums['2P12S'].ng + sums.qwmodelSums['3P8S'].ng + sums.qwmodelSums['EVO_2P'].ng + sums.qwmodelSums['EVO_3P'].ng + sums.qwmodelSums['MC25_3P'].ng) / (sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>          
          <td class="fn">{{ sums.shiftSums[1].fn_saoi_fuse + sums.shiftSums[1].fn_saoi_thermistor + sums.shiftSums[1].fn_finalinspection + sums.shiftSums[2].fn_saoi_fuse + sums.shiftSums[2].fn_saoi_thermistor + sums.shiftSums[2].fn_finalinspection }}</td>
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_saoi_fuse, 0) }}</td>               
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_saoi_thermistor, 0) }}</td> 
          <td class="fn_type">{{ [ sums.shiftSums[1],sums.shiftSums[2]].reduce((sum, obj) => sum + obj.fn_finalinspection, 0) }}</td>               
          <td class="fn">{{ ((sums.shiftSums[1].fn_saoi_fuse + sums.shiftSums[1].fn_saoi_thermistor + sums.shiftSums[1].fn_finalinspection + sums.shiftSums[2].fn_saoi_fuse + sums.shiftSums[2].fn_saoi_thermistor + sums.shiftSums[2].fn_finalinspection)/(sums.shiftSums[1].input + sums.shiftSums[2].input) * 100).toFixed(1) }}%</td>
        </tr>   
        <tr v-for="qw in qwTableData.data" :key="qw.id" class="qwall">
          <template v-for="(header, index) in headers">
            <!-- Use header instead of qwheader --> 
            <td v-if="header === 'Shift'" :key="index" colspan="2">Q-WALL</td>          
            <td v-if="header === 'Model'" :key="index" @click="editCell(qwTableName, qw.id, 'model', $event)">{{ qw.model }}</td>
            <td v-if="header === 'Target'" :key="index" ></td>
            <td v-if="header === 'Input'" :key="index" ></td>
            <td v-if="header === 'OK'" :key="index" @click="editCell(qwTableName, qw.id, 'ok', $event)">{{ qw.ok }}</td>
            <td v-if="header === 'NG'" :key="index" @click="editCell(qwTableName, qw.id, 'ng', $event)">{{ qw.ng }}</td>
            <td v-if="header === 'Fuse'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_fuse', $event)">{{ qw.ng_fuse }}</td>
            <td v-if="header === 'Thermistor'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_thermistor', $event)">{{ qw.ng_thermistor }}</td>
            <td v-if="header === 'Fiducial'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_fiducial', $event)">{{ qw.ng_fiducial }}</td>
            <td v-if="header === 'QR-Code'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_qrcode', $event)">{{ qw.ng_qrcode }}</td>
            <td v-if="header === 'Burned'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_burned', $event)">{{ qw.ng_burned }}</td>
            <td v-if="header === 'Black Fuse'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_blackfuse', $event)">{{ qw.ng_blackfuse }}</td>
            <td v-if="header === 'Others'" :key="index" @click="editCell(qwTableName, qw.id, 'ng_others', $event)">{{ qw.ng_others }}</td>
          </template>
        </tr> 
      </table>                 
      <br>
      <p>Daily Report</p>
      <table id="dailyReport">
        <tr><th v-for="(drheader, index) in drHeaders" :key="index">{{ drheader }}</th></tr>      
        <tr v-for="dr in drTableData.data" :key="dr.id">
            <template v-for="(drheader, index) in drHeaders"> 
                <td v-if="drheader === 'Contents'" :key="index"  @click="editCell(drTableName, dr.id, drheader, $event)">{{ dr.contents }}</td>                
            </template>
          </tr>  
        </table>                     
      <br>
      <p>Down Time</p>
      <table id="downTime">
        <tr><th v-for="(dtheader, index) in dtHeaders" :key="index">{{ dtheader }}</th></tr>      
        <tr v-for="dt in dtTableData.data" :key="dt.id">
            <template v-for="(dtheader, index) in dtHeaders">
                <td v-if="dtheader === 'Shift'" :key="index"  @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.shift }}</td>
                <td v-if="dtheader === 'Model'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.model }}</td>
                <td v-if="dtheader === 'Start_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.start_time }}</td>
                <td v-if="dtheader === 'End_time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.end_time }}</td>
                <td v-if="dtheader === 'Time'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.time }}</td>
                <td v-if="dtheader === 'CLASS1'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class1 }}</td>
                <td v-if="dtheader === 'CLASS2'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class2 }}</td>
                <td v-if="dtheader === 'CLASS3'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.class3 }}</td>
                <td v-if="dtheader === 'Detail'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.detail }}</td>                                
                <td v-if="dtheader === 'Note'" :key="index" @click="editCell(dtTableName, dt.id, dtheader, $event)">{{ dt.note }}</td>   
            </template>
          </tr>  
        </table>   
      </div>    
      
      <Modal v-if="showModal" @onClose="showModal = false; callSearchData();" :line-name="lineName"/>
      <dtModal v-if="showdtModal" @onClose="showdtModal = false; callSearchData();" :line-name="lineName"/>         
      <drModal v-if="showdrModal" @onClose="showdrModal = false; callSearchData();" :line-name="lineName"/> 
      <qwModal v-if="showqwModal" @onClose="showqwModal = false; callSearchData();" :line-name="lineName"/> 
      <rdModal v-if="showrdModal" @onClose="showrdModal = false; callSearchData();" :line-name="lineName"/> 
  </div>
</template>

<script>
import Modal from "./tr3smt_prm.vue";
import qwModal from "./tr3smt_qwm.vue";
import dtModal from "../modal_downTime.vue";
import drModal from "../modal_dailyReport.vue";
import rdModal from "../modal_repeatData.vue";

import { downloadExcel, editCellValue} from "../../utils.js";
import { useForm } from '../0_formStyle/line/form_pr_vw';

export default {
  components: { Modal, dtModal, drModal, qwModal, rdModal },     
  methods: {
    downExcel () { downloadExcel([this.prTableData.data, this.dtTableData.data, this.drTableData.data, this.qwTableData.data], "Production Data.xlsx", [], ["PR Data", "DT Data", "DR Data", "QW Data"]);},
    editCell(tableName, id, header, event) {
      const currentValue = event.target.innerText;
      const newValue = prompt(`Edit value for ${header} (ID: ${id}):`, currentValue);
      if (newValue !== null && newValue !== currentValue) {
          editCellValue(tableName, id, header, newValue, this.callSearchData);
      }
    },
  },  
  setup() {    
    const lineName = "TR3 SMT";
    return useForm(lineName);
  }
};
</script>

<style scoped src="../0_formStyle/line/style_line_pr.css"></style>