<template>
  <div class="dashboard">
    <h1 class="dashDate">{{ date.year }} / {{ date.month }} / {{ date.day }} </h1>  
    <div class="tables">        
      <table class="custom-table">
        <tr><th colspan="7">1st Factory</th><th colspan="7">2nd Factory</th></tr>
        <tr>
          <th>Line</th><th>Model</th><th>OK</th><th>NG</th><th>TIME</th><th>Check</th><th>M.Check</th>
          <th>Line</th><th>Model</th><th>OK</th><th>NG</th><th>TIME</th><th>Check</th><th>M.Check</th>
        </tr>
        <tr v-for="(row, index) in formattedData" :key="index">        
          <td v-for="(cell, cellIndex) in row" :key="cellIndex" :style="getCellStyle(row, cellIndex)" :class="getClass(cell)">
            {{ cell }}
          </td>
        </tr>
      </table>
    </div>  
      <table class="parameter-table">
          <tr><th>Calibration</th><th>Tray 1st</th><th>Tray 2nd</th><th>Evaluation</th><th>M.Spare 1st</th><th>M.Spare 2nd</th>
              <th>P.Spare 1st</th><th>P.Spare 2nd</th><th>Parameter</th>
              <th class="blank"></th>
              <th>EVO 2P</th><th>EVO 3P</th><th>EV2020</th><th>EV2020 FFC</th>
              <th>BMW 12V</th><th>BMW 48V</th><th>Ford FR</th><th>Ford RR</th>
          </tr>
          <tr>
            <td :style="calibrationtableColor">Expire</td>
            <td :style="trayCellStyle1st">{{trayResult1st}}</td>
            <td :style="trayCellStyle2nd">{{trayResult2nd}}</td> 
            <td :style="evaluationtableColor">Evaluation</td>
            <td :style="spare1stTableColor">M.Spare 1st</td>
            <td :style="spare2ndTableColor">M.Spare 2nd</td> 
            <td :style="productionSpare1stColor">P.Spare 1st</td>
            <td :style="productionSpare2ndColor">P.Spare 2nd</td> 
            <td :style="parameterColor">Parameter</td> 
            <td class="blank"></td>
            <td>{{ packingQty_evo2p }}</td>
            <td>{{ packingQty_evo3p }}</td>
            <td>{{ packingQty_ev2020frrr }}</td>
            <td>{{ packingQty_ev2020ffc }}</td>
            <td>{{ packingQty_bmw12v }}</td>
            <td>{{ packingQty_bmw48v }}</td>
            <td>{{ packingQty_ford_front }}</td>
            <td>{{ packingQty_ford_rear }}</td>
          </tr>
      </table>         
  </div>
</template>

<script>
import { productionData, checktargetSearch, checksheetSearchData, maintenanceChecktargetSearch, maintenanceChecksheetSearchData,
         updateResultData, fetchDataForAddData, updateNgRateTargets, } from './data.js'
import { getCurrentDate, } from './utils.js';
import { calibrationtableColor } from './calibration.js';
import { evaluationtableColor } from './evaluation.js';
import { trayResult1st, trayResult2nd, trayCellStyle1st, trayCellStyle2nd } from './trayStock.js';
import { spare1stTableColor, spare2ndTableColor } from './spare.js';
import { productionSpare1stColor, productionSpare2ndColor } from './productionSpare.js';
import { parameterColor } from './parameter.js';
import { packingQty, packingSums } from './packingQty.js';

export default {
  data() {
    return {
      date: getCurrentDate(),       
      productionLines: productionData,
      evaluationtableColor,
      calibrationtableColor,
      trayResult1st, trayResult2nd, trayCellStyle1st, trayCellStyle2nd,
      spare1stTableColor, spare2ndTableColor,   
      productionSpare1stColor, productionSpare2ndColor,
      parameterColor,
      intervalHandle: null
    }
  },
  methods: {
    getClass(checkValue) {if (checkValue === 'ok') {return 'ok';} else if (checkValue === 'ng') {return 'ng';} else if (checkValue === 'data') {return 'nodata';} return ''; },
    async loadData() {
      await updateResultData(); this.productionLines = [...productionData];
      await checksheetSearchData(); this.productionLines = [...productionData];
      await checktargetSearch(); this.productionLines = [...productionData];
      await maintenanceChecksheetSearchData(); this.productionLines = [...productionData];
      await maintenanceChecktargetSearch(); this.productionLines = [...productionData];
      await fetchDataForAddData().then(() => { updateNgRateTargets(); this.productionLines = [...productionData]; });
      await packingQty(); 
    },    

    getCellStyle(row, cellIndex) {
      if (cellIndex === 2) { 
        const lineName = row[0];
        const lineData = this.productionLines.find(line => line.line === lineName);
        const okAchieve = lineData ? lineData.okAchieve : 0;        
        return this.aS(okAchieve, 100); 
      } else if (cellIndex === 9) { 
        const lineName = row[7]; //made by kwon
        const lineData = this.productionLines.find(line => line.line === lineName);
        const okAchieve = lineData ? lineData.okAchieve : 0;        
        return this.aS(okAchieve, 100); 
      } else if (cellIndex === 3) { 
        const lineName = row[0];
        const lineData = this.productionLines.find(line => line.line === lineName);
        const ngRate = lineData ? lineData.ngRate : 0;
        const ngRateTarget = lineData ? lineData.ngRateTarget : 0;
        return this.aS(ngRateTarget, ngRate);
      } else if (cellIndex === 10) { 
        const lineName = row[7];
        const lineData = this.productionLines.find(line => line.line === lineName);
        const ngRate = lineData ? lineData.ngRate : 0;
        const ngRateTarget = lineData ? lineData.ngRateTarget : 0;
        return this.aS(ngRateTarget, ngRate);
      }

      return {};
    },
      
    aS(target, result) {
      if (result === 0 || target ===0) { return { backgroundColor: 'transparent' }; }
      const value = target / result ;
      if (isNaN(value)) { return { backgroundColor: 'transparent' }; }
      else if (value < 0.8499) { return { backgroundColor: 'rgba(255, 0, 0, 0.7)' }; }
      else if (value < 0.9499) { return { backgroundColor: 'rgba(255, 255, 0, 0.3)' };}
      else { return { backgroundColor: 'rgba(144, 238, 144, 0.3)' }; }
    },

    startAutoRefresh() {
      const autoRefreshInterval = 600 * 1000; // 600 seconds (10 minutes) made by kwon
      this.intervalHandle = setInterval(() => { this.date = getCurrentDate();  this.loadData(); }, autoRefreshInterval);
    }

  },
  computed: {
    formattedData() {
      const formatted = [];
      const factory1Data = this.productionLines.filter(line => line.factory === '1st');
      const factory2Data = this.productionLines.filter(line => line.factory === '2nd');

      const maxLength = Math.max(factory1Data.length, factory2Data.length);
      for (let i = 0; i < maxLength; i++) {
        const row = [];

        if (factory1Data[i]) {
          row.push(
            factory1Data[i].line,
            factory1Data[i].model,
            `${factory1Data[i].ok-factory1Data[i].ng_qw} EA (${factory1Data[i].okAchieve}%)`,
            `${factory1Data[i].ng+factory1Data[i].ng_qw} EA (${factory1Data[i].ngRate}%)`,
            `${factory1Data[i].work_minute} min (${factory1Data[i].time} min)`,
            factory1Data[i].check,                        
            factory1Data[i].mcheck,      
          );
        } else {
          row.push('', '', '', '', '', '', '');
        }

        if (factory2Data[i]) {          
          row.push(
            factory2Data[i].line,
            factory2Data[i].model,
            `${factory2Data[i].ok-factory2Data[i].ng_qw} EA (${factory2Data[i].okAchieve}%)`,
            `${factory2Data[i].ng+factory2Data[i].ng_qw} EA (${factory2Data[i].ngRate}%)`,
            `${factory2Data[i].work_minute} min (${factory2Data[i].time} min)`,
            factory2Data[i].check,                     
            factory2Data[i].mcheck,      
          );
        } else {
          row.push('', '', '', '', '', '', '');
        }

        formatted.push(row);
      }
      return formatted;
    },
    packingQty_evo2p() {return packingSums('evo_2p');},
    packingQty_evo3p() {return packingSums('evo_3p');},
    packingQty_ev2020frrr() {return packingSums('ev2020frrr');},
    packingQty_ev2020ffc() {return packingSums('ev2020ffc');},
    packingQty_bmw12v() {return packingSums('bmw12v');},
    packingQty_bmw48v() {return packingSums('bmw48v');},
    packingQty_ford_front() {return packingSums('ford_front');},
    packingQty_ford_rear() {return packingSums('ford_rear');},
  },
    
  mounted() { this.loadData(); this.startAutoRefresh(); },
  unmounted() { clearInterval(this.intervalHandle) },

}

</script>

<style scoped>
  body { font-family: Arial, sans-serif; background-color: #f0f0f0; margin: 0; padding: 0; }
  .dashDate {font-size: 20px; font-weight: bold}
  .dashboard { max-width: 100%; margin: 0 auto; padding: 20px; }
  .custom-table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }      
  .custom-table th { border: 1px solid #ddd; padding: 2px;  text-align: center; font-weight: bold; font-size: 20px; background-color: #5586f0; color: white;}
  .custom-table td { border: 1px solid #ddd; padding: 2px;  text-align: center; font-weight: bold; font-size: 15px; padding: 10px 5px;} 
  .custom-table tr:nth-child(even) {  background-color: #f2f2f2;}
  .tables { display: flex; flex-wrap: wrap; justify-content: space-between; }  
  @media screen and (max-width: 600px) { .tables { flex-direction: column; } }
  .blank {font-size: 15px !important; background-color: white !important;}
  .parameter-table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }  
  .parameter-table th { border: 1px solid #ddd; padding: 2px;  text-align: center; font-weight: bold; font-size: 20px; background-color: #5586f0; color: white;}
  .parameter-table td { border: 1px solid #ddd; padding: 2px;  text-align: center; font-weight: bold; font-size: 15px;} 
  .parameter-table tr:nth-child(even) {  background-color: #f2f2f2;}  
  .ok { background-color: #089927; color: #000000; }
  .ng { background-color: #f87b6a; color: #000000; }
  .nodata { background-color: #def07c; color: #000000; }
</style>
